<template>
  <div
    v-if="!$g.isRefresh"
    class="auth-wrapper auth-v1 px-2"
  >
    <div class="auth-inner py-2">

      <!-- Login -->
      <b-card class="mb-0">
        <div class="text-center mb-1">
          <b-img
            class="text-center"
            src="@/assets/images/logo/logo.png"
            fluid
          />
        </div>
        <!-- <h1 class="brand-text text-center text-primary ml-1">
            separis
          </h1> -->

        <b-card-title class="mb-1">
          {{ $g.browserLocale === 'fr' ? 'Enregistrement' : 'Anmeldung' }}
        </b-card-title>
        <b-card-text class="mb-2">
          <!-- {{ $g.Test + $g.Val + $g.X + ' => ' + $g.browserLocale}} -->
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              :label="$g.browserLocale === 'fr' ? 'Entrer valide courielle' : 'Benutzer/E-Mail'"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|email"
              >
                <b-form-input
                  ref="email"
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                  @blur="setTFA"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Entrer valide courielle' : 'Gültige E-Mail eingeben' }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ $g.browserLocale === 'fr' ? 'Mot de passe' : 'Passwort' }}</label>
              </div>
              <validation-provider
                #default="{ errors }"
                rules="required"
                mode="aggressive"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Le mot de passe doit être specifié' : 'Das Passwort darf nicht leer sein' }}</small>
              </validation-provider>
              <div class="text-right">
                <!-- <b-link:to="{name:'auth-forgot-password'}"> -->
                <b-link @click="showForgetPwd">
                  <small>{{ $g.browserLocale === 'fr' ? 'Oublié mot de passe ou problème avec ADF?' : 'Passwort vergessen oder Problem ZFA?' }}</small>
                </b-link>
              </div>
            </b-form-group>

            <!-- TFA -->
            <b-form-group v-show="isTFA"
              label-for="totpCode"
              :label="$g.browserLocale === 'fr' ? 'Entrer valide code ADF' : 'Code 2FA'"
            >
              <b-form-input v-mask="'### ###'"
                id="tfaCode"
                v-model="code"
                name="code"
              />
            </b-form-group>

            <!-- submit button -->
            <b-button
              ref="anmelden"
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              {{ $g.browserLocale === 'fr' ? 'Enregister' : 'Anmelden' }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
    <b-modal
      v-model="benutzerInaktiv"
      title="Benutzer inaktiv"
      variant="outline-danger"
      ok-title="OK"
      ok-only
    >
      Der Benutzer ist inaktiv!
    </b-modal>

    <b-modal
      id="modalAGB"
      title="Bestimmungen"
      cancel-title="Ablehnen"
      ok-title="Akzeptieren"
      cancel-variant="outline-secondary"
      size="lg"
      centered
      no-close-on-backdrop
      @ok="checkAGB"
    >
    <div v-if="role === 'klient'">
        <p v-html="$t('AGBKlient')"></p>
        </div>
      <div v-else>
        <p v-html="$t('AGBText')"></p>
     </div>
    </b-modal>

  </div>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'

import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BModal, BImg, ModalPlugin, BOverlay,
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
// import global from '@/service/global'
// import dos from '@/service/dossier'
import { required, email } from '@validations'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ben from '@/service/benutzer'

Vue.use(ModalPlugin)
Vue.use(VueMask)

export default {
  components: {
    // BSV
    BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BLink, BImg, BCardText, BInputGroup, BInputGroupAppend, ValidationProvider, ValidationObserver, BModal, BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    setInteractionMode('lazy') // Setting the validation mode to lazy, meaning, that validation occurs after leaving the field...
    return {
      role: 'user',
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      benutzerInaktiv: false,
      isTFA: false,
      code: '',
      isAGGOK: false,
      isCalc: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    // this.$nextTick(() => this.$refs.email.focus())
    if (this.$refs.email !== undefined) this.$nextTick(() => this.$refs.email.focus())
  },
  methods: {
    async login() {
      this.isCalc = true
      try {
        const r = await this.$root.login(this.userEmail, this.password, this.code.replace(' ', ''))
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (r || JSON.parse(localStorage.getItem('userData'))) {
          // this.usr = r.data[0]
          // if (this.usr.Ist2FA && this.code.length < 6) return
          // IMPORTANT: Here is the entrypoint for the navigation...
          // if (this.$g.user.role !== 'klient' && this.$g.user.IstAGB === false) {
          if (this.$g.user.IstAGB === false) {
            this.role = this.$g.user.role
            await this.$bvModal.show('modalAGB')
            await this.$nextTick() // Trick to get $refs not to be undefined...
          } else {
            const klientView = userData.Ability.includes('klientfragebogen') ? '/fragebogen' : '/belege'
            this.$router.replace(this.$g.user.role === 'klient' ? klientView : '/')
              .then(() => {
                this.$root.showToast(this.$g.browserLocale === 'fr' ? 'Connexion au système réussi' : 'Erfolgreiche Anmeldung',
                  this.$g.browserLocale === 'fr' ? 'Vous avez réussi à vous connecter au système!' : 'Sie haben sich erfolgreich am System angemeldet!')
              })
              .catch(error => {
                this.isCalc = false
                this.$refs.loginForm.setErrors(error.message)
              })
          }
          this.isCalc = false
        } else {
          this.$root.showToast(this.$g.browserLocale === 'fr' ? 'La connexion au système a échoué!' : 'Die Anmeldung am System ist fehlgeschlagen!',
            this.$g.browserLocale === 'fr' ? 'Vous avez un utilisateur inexistant ou le mot de passe est faux!' : 'Sie haben einen falschen Benutzernamen oder ein falsches Passwort angegeben!')
          this.isCalc = false
        }
      } catch (error) {
        this.errored = true
        this.isCalc = false
      }
    },
    async showForgetPwd() {
      // console.log('fp')
      this.$router.push({ name: 'auth-forgot-password' })
    },
    async setTFA() {
      const r = await ben.getIsTFA(this.userEmail)
      this.isTFA = r.data[0]
    },
    async checkAGB(bvModalEvent) {
      bvModalEvent.preventDefault()

      this.$router.replace(this.$g.user.role === 'klient' ? '/fragebogen' : '/')
        .then(() => {
          ben.putBenutzerUpdateIstAGB(this.$g.user.BenID, true)
          this.$root.showToast(this.$g.browserLocale === 'fr' ? 'Connexion au système réussi' : 'Erfolgreiche Anmeldung',
            this.$g.browserLocale === 'fr' ? 'Vous avez réussi à vous connecter au système!' : 'Sie haben sich erfolgreich am System angemeldet!')
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.message)
        })

      this.$nextTick(() => {
        this.$bvModal.hide('modalAGB')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
